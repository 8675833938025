import React from 'react';
import { Link } from 'gatsby';
import { Layout, SEO, Container } from 'components/common';
import HomeLink from 'components/theme/Header/Navbar/HomeLink';
import charts from 'assets/illustrations/charts.svg';
import { Header, HeaderContent } from '../templates/data-styles';
import { Wrapper } from '../components/theme/Header/Navbar/styles';

export default () => (
  <Layout>
    <SEO title='404: Error' location='/404' />
    <Header>
      <Container>
        <HeaderContent as={Wrapper}>
          <HomeLink />
        </HeaderContent>
      </Container>
    </Header>
    <Container style={{ marginTop: '4rem' }}>
      <Wrapper style={{ alignItems: 'flex-start' }}>
        <div style={{ flex: 1 }}>
          <h1>Uh oh!</h1>
          <p>Something went wrong.</p>
          <p>You may be lost — if not, please <Link to='/#contact'>reach out to us</Link>.</p> {/* eslint-disable-line */}
        </div>
        <img src={charts} alt='' style={{ flex: 1 }} />
      </Wrapper>
    </Container>
  </Layout>
);
